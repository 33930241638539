<template>
  <div style="min-width:1290px;" v-loading="loading">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="w1300 SignUpForms-box">
          <div style="display: flex;justify-content: space-between;align-items: center;margin:50px 0 30px 0;">
            <div style="display: flex;align-items: center;cursor: pointer;" @click="goBack">
              <div><img src="./../../static/back.png" alt="" style="width: 13px;height: 14px;"></div>
              <div style="font-size: 16px;color: rgb(0, 82, 217);margin-left:5px;">返回订单列表</div>
            </div>
            <div style="font-size: 24px;font-weight: 500;color: #000;">发票详情</div>
            <div style="width: 140px;"></div>
          </div>
          <div class="shadow">
            <div style="margin-top:50px;">
              <div class="img-tip">
                <div class="number-img"><img src="./../../static/01-img.png" alt=""></div>
                <div class="number-tip">开票信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="ruleForm" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="发票类型：" prop="invoiceType">
                    <el-select v-model="name" disabled style="width: 230px;height:40px;">
                      <el-option label="增值税普通发票" value="1" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="抬头名称：" prop="companyName">
                    <el-input v-model="ruleForm.companyName" disabled placeholder="请输入抬头名称" style="width: 230px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业信用代码：" prop="creditCode">
                    <el-input v-model="ruleForm.creditCode" disabled placeholder="请输入企业信用代码" style="width: 230px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开票明细：" prop="detailed">
                    <el-input v-model="ruleForm.detailed" style="width: 230px;height:40px;" disabled />
                  </el-form-item>
                  <el-form-item label="开票金额：" prop="amout">
                    <el-input v-model="ruleForm.amout" disabled placeholder="请输入开票金额" style="width: 230px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="电子发票发送邮箱：" prop="email">
                    <el-input v-model="ruleForm.email" disabled placeholder="请输入电子发票发送邮箱" style="width: 230px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="备注：" prop="remarks">
                    <el-input :rows="4" type="textarea" disabled placeholder="请输入备注" v-model="ruleForm.remarks" style="width: 1075px;height: 40px;" />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div style="margin-top:50px;">
              <div>
                <div class="img-tip">
                  <div class="number-img"><img src="./../../static/02-img.png" alt=""></div>
                  <div class="number-tip">发票下载</div>
                </div>
                <div style="margin-top:35px; margin-left: 53px;" class="annex">
                  <div class="de">发票下载：</div>
                  <div class="herfa">
                    <a v-if="ruleForm.invoiceFileLink&&ruleForm.invoiceFileLink!=''" :href="ruleForm.invoiceFileLink">发票</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { InvoiceDetail } from "@/api/index.js"
let router = useRouter()
let name = ref('增值税普通发票')
let formSize = ref('default')
let ruleFormRef = ref()
let loading = ref(true)
let ruleForm = ref({})
let goBack = () => {
  router.push({ path: '/personalCenter' })
  localStorage.setItem('active', '2');
}
let messageList = async () => {
  await InvoiceDetail({ projectId: router.currentRoute.value.query.projectId }).then(res => {
    console.log(res);
    ruleForm.value = res.data
  })
}
onMounted(async () => {
  await messageList()
  loading.value = false
})
</script>
<style scoped>
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.SignUpForms-box {
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
  padding-bottom: 80px;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  background: url("./../../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.de {
  height: 21px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(32, 35, 50, 1);
}
.herfa {
  margin-top: 14px;
}
.herfa a {
  height: 21px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(31, 112, 247, 1);
  text-decoration: none;
}
.red-tip {
  margin-top: 60px;
  margin-left: 53px;
  width: 644px;
  height: 52px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26px;
  color: rgba(212, 48, 48, 1);
}
</style>
